import React, { useState } from 'react';
import styles from './css/Winners.module.css';
import { FooterPublic } from "../shared/Footer";
import image99 from "../../img/image99.svg";
import minus from "../../img/minus.svg";
import plus from "../../img/Plus.svg";

export const Winners = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDetails = () => {
        setIsOpen(prev => !prev);
    };

    return (
        <section className={styles.public}>
            <div className={styles.container} style={{ backgroundImage: `url(${image99})` }}>
                <h2 className={styles.title}>Победители</h2>
                <p className={styles.subtitle}>Поздравляем победителей Рекламной игры!</p>
                <div className={styles.block}>

                    <div className={styles.draw}>
                        <p>Розыгрыш Главного приза</p>
                        <p>Розыгрыш 4 октября</p>
                        <p>Розыгрыш 25 сентября</p>
                        <p>Розыгрыш 18 сентября</p>
                        <p>Розыгрыш 4 сентября</p>
                        <p>Розыгрыш 28 августа</p>
                        <p>Розыгрыш 21 августа</p>
                        <p>Розыгрыш 14 августа</p>
                    </div>

                    <div className={styles.drawMobile}>
                        <p className={styles.drawTitle} onClick={toggleDetails}>
                            Розыгрыш Главного приза
                            <img
                                src={isOpen ? minus : plus}
                                alt={isOpen ? "Скрыть" : "Показать"}
                            />
                        </p>
                        {isOpen && (
                            <div className={styles.drawSubtitle}>
                                <p>Розыгрыш 4 октября</p>
                                <p>Розыгрыш 25 сентября</p>
                                <p>Розыгрыш 18 сентября</p>
                                <p>Розыгрыш 4 сентября</p>
                                <p>Розыгрыш 28 августа</p>
                                <p>Розыгрыш 21 августа</p>
                                <p>Розыгрыш 14 августа</p>
                            </div>
                        )}

                    </div>
                    <div className={styles.winners}>
                        <input type="text" placeholder="Введите игровой код или фамилию" required/>
                        <table>
                            <thead>
                            <tr>
                                <th>ФИО</th>
                                <th>Игровой код</th>
                                <th>Приз</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Агеенко Андрей Михайлович</td>
                                <td>00049567</td>
                                <td>Портативная колонка Sven PS-380</td>
                            </tr>
                            <tr>
                                <td>Алехнович Елена Константиновна</td>
                                <td>00050775</td>
                                <td>Сертификат «Корона» на сумму 100 рублей</td>
                            </tr>
                            <tr>
                                <td>Алехнович Елена Константиновна</td>
                                <td>00050775</td>
                                <td>Сертификат «Корона» на сумму 100 рублей</td>
                            </tr>
                            <tr>
                                <td>Алехнович Елена Константиновна</td>
                                <td>00050775</td>
                                <td>Сертификат «Корона» на сумму 100 рублей</td>
                            </tr>
                            </tbody>
                        </table>

                        <div className={styles.mobileTable}>
                            <div className={styles.blockTable}>
                                <p>ФИО</p>
                                <span>Алехнович Елена Константиновна</span>
                                <p>Игровой код</p>
                                <span>00050775</span>
                                <p>Приз</p>
                                <span>Сертификат «Корона» на сумму 100 рублей</span>
                            </div>
                            <div className={styles.blockTable}>
                                <p>ФИО</p>
                                <span>Алехнович Елена Константиновна</span>
                                <p>Игровой код</p>
                                <span>00050775</span>
                                <p>Приз</p>
                                <span>Сертификат «Корона» на сумму 100 рублей</span>
                            </div>
                            <div className={styles.blockTable}>
                                <p>ФИО</p>
                                <span>Алехнович Елена Константиновна</span>
                                <p>Игровой код</p>
                                <span>00050775</span>
                                <p>Приз</p>
                                <span>Сертификат «Корона» на сумму 100 рублей</span>
                            </div>
                            <div className={styles.blockTable}>
                                <p>ФИО</p>
                                <span>Алехнович Елена Константиновна</span>
                                <p>Игровой код</p>
                                <span>00050775</span>
                                <p>Приз</p>
                                <span>Сертификат «Корона» на сумму 100 рублей</span>
                            </div>
                        </div>

                        <ul>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                            <li>7</li>
                            <li>8</li>
                            <li>9</li>
                            <li>10</li>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                                 fill="#5f6368">
                                <path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"/>
                            </svg>
                        </ul>
                    </div>
                </div>
            </div>
            <FooterPublic/>
        </section>
    );
}
