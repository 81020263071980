import React from 'react';
import styles from "./css/Profile.module.css";

export const Profile = () => {
    return (
        <section className={styles.profile}>
            <h1 className={styles.h1}>Мои данные</h1>
            <p className={styles.subtitle}>Если вы хотите изменить свои данные,</p>
            <p className={styles.subtitle}>обратитесь по номеру "Информационной</p>
            <p className={styles.subtitle}>линии" +375 44 778 11 00</p>

            <div className={styles.form}>
                <label htmlFor="lastName">Фамилия</label>
                <input id="lastName" type="text" placeholder="Иванов"/>

                <label htmlFor="name">Имя</label>
                <input id="name" type="text" placeholder="Иван"/>

                <label htmlFor="middleName">Отчество</label>
                <input id="middleName" type="text" placeholder="Иванович"/>

                <label htmlFor="birthdate">Дата рождения</label>
                <input id="birthdate" type="date" value="1995-05-05"/>

                <label htmlFor="phone">Телефон</label>
                <input id="phone" type="tel" placeholder="asdsad"/>

                <label htmlFor="email">Email</label>
                <input id="email" type="text" placeholder="asdsad"/>

                <div className={styles.checkbox}>
                    <label htmlFor="confirmDeletion">Я хочу удалить свой аккаунт и все предоставленные данные</label>
                    <input id="confirmDeletion" type="checkbox" placeholder="asdsad"/>
                </div>

                <button className={styles.delete}>Удалить аккаунт</button>
            </div>
        </section>
    );
};