import React from 'react';
import styles from './css/Form.module.css';
import image21 from "../../img/image21.svg";

export const Form = () => {
    return (
        <section className={styles.form} id="form" style={{ backgroundImage: `url(${image21})` }}>
            <div className={styles.modalForm}>
                <h2>Вход в личный кабинет</h2>
                <p>Для загрузки чека зарегистрируйтесь или войдите в свой личный кабинет</p>
                <input type="email" name="email" placeholder="Email"/>
                <input type="password" name="password" placeholder="Пароль"/>
                <div className={styles.checkContainer}>
                    <label className={styles.check}>
                        <input type="checkbox"/>
                        Запомнить меня
                    </label>
                    <span>Забыли пароль?</span>
                </div>
                <a href="/" className={styles.entry}>Вход</a>
                <a href="/" className={styles.registration}>Регистрация</a>
            </div>
        </section>
    );
};
