import React, {useState} from 'react';
import styles from "./css/UploadReceipt.module.css";
import {api, raffleId} from "../../index";

export const UploadReceipt = () => {
    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleFileChange = (e) => {
        const filesArray = Array.from(e.target.files);
        setSelectedFiles((prevFiles) => [...prevFiles, ...filesArray]);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const filesArray = Array.from(e.dataTransfer.files);
        setSelectedFiles((prevFiles) => [...prevFiles, ...filesArray]);
    };

    const handleSubmit = async () => {
        const formData = new FormData();
        selectedFiles.forEach(file => {
            formData.append('image[]', file); // Append each file to the FormData
        });
        formData.append('raffleId', `${raffleId}`); // Replace with actual values

        try {
            const response = await fetch(`${api}/receipts/`, {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();
            console.log('Response from server:', data);
            // Optionally clear the files after submission
            setSelectedFiles([]);
        } catch (error) {
            console.error('Error uploading files:', error);
        }
    };

    const clearFiles = () => {
        setSelectedFiles([]);
    };

    return (
        <section className={styles.newReceipt}>
            <h1 className={styles.h1}>Мои данные</h1>
            <p className={styles.subtitle}>Можно загрузить две фотографии, если</p>
            <p className={styles.subtitle}>на одной чек не помещается</p>

            <div
                className={styles.form}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >
                <svg xmlns="http://www.w3.org/2000/svg" height="72px" viewBox="0 -960 960 960" width="72px" fill="#000">
                    <path
                        d="M440-320v-326L336-542l-56-58 200-200 200 200-56 58-104-104v326h-80ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"/>
                </svg>

                {selectedFiles.length > 0 && (
                    <ul className={styles.fileList}>
                        {selectedFiles.map((file, index) => (
                            <li key={index} className={styles.fileItem}>{file.name}</li>
                        ))}
                    </ul>
                )}

                <label htmlFor="">Перетащите сюда фото чека или выберите файл</label>
                <div className={styles.formats}>JPEG, PNG до 10 MB</div>

                <input
                    id="file"
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    multiple
                    style={{display: 'none'}}
                    onChange={handleFileChange}
                />

                <div className={styles.buttons}>
                    <label htmlFor="file" className={styles.select}>Выбрать</label>
                    <button className={styles.send} onClick={handleSubmit}>Отправить</button>
                    <button className={styles.clear} onClick={clearFiles}>Очистить</button>
                </div>
            </div>
        </section>
    );
};
