import React, { useCallback, useEffect, useState } from 'react';
import styles from './css/Raffle.module.css';
import { useParams } from "react-router-dom";
import { api } from "../../index";
import Confirm from "../shared/Confirm";

export const Raffle = () => {
    const { raffleId } = useParams();
    const [raffle, setRaffle] = useState({});
    const [receipts, setReceipts] = useState([]);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [selectedReceiptId, setSelectedReceiptId] = useState(null);

    const fetchRaffle = useCallback(() => {
        fetch(`${api}/raffle/?id=${raffleId}`, {
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((data) => setRaffle(data))
            .catch((err) => console.error('Error adding project:', err));
    }, [raffleId]);

    const fetchReceipts = useCallback(() => {
        fetch(`${api}/receipts/?id=${raffleId}`, {
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((data) => setReceipts(data))
            .catch((err) => console.error('Error adding project:', err));
    }, [raffleId]);

    const acceptReceipt = (receiptId) => {
        setSelectedReceiptId(receiptId);

    };

    const declineReceipt = (receiptId) => {
        setSelectedReceiptId(receiptId);
        setIsConfirmOpen(true);
    };

    const handleConfirmDecline = () => {
        // Handle receipt decline logic here, e.g., making an API call
        console.log(`Declining receipt: ${selectedReceiptId}`);
        setIsConfirmOpen(false);
        setSelectedReceiptId(null);
    };

    const handleCloseModal = () => {
        setIsConfirmOpen(false);
        setSelectedReceiptId(null);
    };

    useEffect(() => {
        fetchRaffle();
        fetchReceipts();
    }, [fetchRaffle, fetchReceipts]);

    return (
        <div className={styles.raffle}>
            <h1 className={styles.h1}>{raffle.name}</h1>
            <div className={styles.table}>
                {(receipts ?? []).map((receipt) => (
                    <div className={styles.row} key={receipt.id}>
                        <div className={styles.id}>{receipt.id}</div>
                        <div className={styles.user}>{receipt.lastName} {receipt.firstName} {receipt.middleName}</div>
                        <a className={styles.receipt} href={`${api}/receipts/${receipt.image}`} target="_blank" rel="noopener noreferrer">Receipt</a>
                        <div className={styles.number}>{receipt.acceptedNumber ?? '-'}</div>
                        <div className={styles.code}>{receipt.code ?? '-'}</div>
                        <div className={[styles.isAccepted, receipt.isAccepted ? styles.verified : styles.unverified].join(' ')}>
                            {receipt.isAccepted ? "Da" : "Net"}
                        </div>
                        <div className={styles.created}>{new Date(receipt.created).toLocaleDateString()}</div>
                        <div className={styles.acceptedAt}>{receipt.acceptedAt ? new Date(receipt.acceptedAt).toLocaleDateString() : '-'}</div>
                        <button className={styles.button} onClick={() => acceptReceipt(receipt.id)}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="green"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>                        </button>
                        <button className={styles.button} onClick={() => declineReceipt(receipt.id)}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="red"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                        </button>
                    </div>
                ))}
            </div>

            <Confirm
                isOpen={isConfirmOpen}
                onClose={handleCloseModal}
                onConfirm={handleConfirmDecline}
                message="Подтвердите отклонение чека"
            />
        </div>
    );
};
