import React from 'react';
import styles from './css/Footer.module.css';
import image29 from '../../img/image29.svg';
import {Link} from "react-router-dom";

export const FooterAdmin = () => {
    return (
        <footer className={styles.footer}>

        </footer>
    );
};

export const FooterPublic = () => {
    return (
        <section className="footer">
            <div className={styles.container}>
                <div className="footer-container h sb">
                    <a href="/" className="f2">
                        <img className="image-footer" src={image29} alt="Cola"/>
                    </a>
                    <div className="v footer-contacts">
                        <h3>Контакты</h3>
                        <div className="information">
                        <a href="tel:+ 111 111 11 11">+375 44 778 11 00</a>
                        <p>ПН-ПТ с 10:00 до 17:00 <br/>
                            СБ с 13:00 до 15:00</p>
                        </div>
                        <a href="mailto:info@coca-cola-promo.by">info@coca-cola-promo.by</a>
                    </div>
                    <div className="v conditions">
                        <h3><Link to={`/terms`}>Условия использования</Link></h3>
                        <h3><Link to={`/privacy`}>Положение о конфиденциальности</Link></h3>
                        <h3><Link to={`/cookie`}>Политика "cookie"</Link></h3>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="info v c">
                    <p>Унитарное предприятие «Кока-Кола Бевриджиз Белоруссия». УНП 100373962. Республика Беларусь.</p>
                    <p>«Рекламная игра «Выходные. Пикник. Coсa-Cola»: Организатор ООО «ЭфСиБи Бел», УНП 193185741,
                        телефон:
                        +375447781100. Срок проведения (с учетом выдачи призов и публикации результатов) с 01.08.24 по
                        08.12.24.
                        Свидетельство о государственной регистрации №4448 от 18 июля 2024 года, выданное Министерством
                        антимонопольного регулирования и торговли Республики Беларусь.</p>
                    <p>COCA-COLA и BONAQUA являются зарегистрированными товарными знаками The Coca-Cola Company (Зэ
                        Кока-Кола
                        Компани). ©2024 The Coca-Cola Company (Зэ Кока-Кола Компани).</p>
                    <p>SCHWEPPES является зарегистрированным товарным знаком European Refreshments UC (Юропиэн
                        Рефрешментс).</p>
                </div>
            </div>
        </section>
    );
};

export const FooterClient = () => {
    return (
        <footer className={styles.footer}>
            Client footer
        </footer>
    );
};