import React from 'react';
import styles from './css/Privacy.module.css';
import {FooterPublic} from "../shared/Footer";
import {Introduction} from "./Introduction";

export const Privacy = () => {
    return (<div className={styles.public}>
            <Introduction/>
            <div className={styles.container1}>
                <div className={styles.text}>
                    <h1 className={styles.title}>ПОЛИТИКА В ОТНОШЕНИИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ, ПОЛУЧЕННЫХ
                        ПОСРЕДСТВОМ
                        ИНТЕРНЕТ-САЙТА
                        COCA-COLA-PROMO.BY</h1>
                    <div className={styles.textBlock}>
                        <h2>ГЛАВА 1. ОБЩИЕ ПОЛОЖЕНИЯ</h2>
                        <p>1. В Политике в отношении обработки персональных данных, полученных посредством
                            интернет-сайта
                            coca-cola-promo.by (далее – Политика), используются специальные термины в значениях,
                            установленных
                            Законом Республики Беларусь от 07.05.2021 № 99–З «О защите персональных данных» и Законом
                            Республики
                            Беларусь от 10.11.2008 N 455-З «Об информации, информатизации и защите информации» (далее
                            совместно
                            –
                            Законы), а также термины, имеющие следующие значения:</p>
                        <p>1.1. Компания – производственное унитарное предприятие «Кока-Кола Бевриджиз Белоруссия»,
                            зарегистрированное в Едином государственном регистре юридических лиц и индивидуальных
                            предпринимателей
                            под номером 100373962, с местонахождением по адресу: деревня Колядичи,147/2, Сеницкий
                            сельсовет,
                            Минский
                            район, Минская область.</p>
                        <p>1.2. Пользователь – субъект персональных данных, который посетил Сайт или совершил на нем
                            какие-либо
                            действие посредством подключения используемого им технического устройства к Сайту через сеть
                            глобальную
                            компьютерную сеть Интернет.</p>
                        <p>1.3. Рекламная игра – рекламная игра «Выходные. Пикник. Coсa-Cola», зарегистрированная
                            Министерством
                            антимонопольного регулирования и торговли Республики Беларусь 18.07.2024 под номером
                            4448.</p>
                        <p>1.4. Сайт – информационный ресурс Компании в глобальной компьютерной сети Интернет,
                            адресуемый
                            посредством доменного имени coca-cola-promo.by.</p>
                        <p>2. Политика определяет порядок обработки Компанией как оператором персональных данных
                            Пользователей,
                            полученных Компанией посредством Сайта, разъясняет какие, для каких целей и каким образом
                            персональные
                            данные Пользователей обрабатываются, а также излагает имеющиеся в связи с этим у
                            Пользователей
                            права
                            и
                            механизм их реализации. Политика не применяется к обработке сведений из cookies,
                            используемых
                            Сайтом.</p>
                        <p>3. Политика разработана в соответствии с положениями Законов и иных актов законодательства
                            Республики
                            Беларусь, регулирующих отношения, связанные с обработкой персональных данных.</p>

                        <h2>ГЛАВА 2. ПЕРЕЧЕНЬ ОБРАБАТЫВАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ, ЦЕЛЬ, ПРАВОВОЕ ОСНОВАНИЕ И СРОК
                            ОБРАБОТКИ</h2>
                        <p>4. Перечень персональных данных Пользователей, обрабатываемых Компанией в соответствии с
                            Политикой,
                            цель,
                            правовое основание и срок их обработки определены в таблице 1 ниже.</p>

                        <p className={styles.tabsTitle}>Таблица 1. Перечень обрабатываемых персональных данных, цель,
                            правовое основание и срок их
                            обработки</p>
                        <div className={styles.table}>
                            <div className={styles.tableTitle}>
                                <p>Перечень</p>
                                <p>Цель</p>
                                <p>Основание</p>
                                <p>Срок</p>
                            </div>

                            <div className={styles.tableSubtitle}>
                                <div className={styles.mobileTitle}>Перечень
                                    <span>1. Фамилия, имя, отчество (при наличии) Пользователя.
                                <br/> <br/>  2. Дата рождения Пользователя.
                                    <br/> <br/> 3. Абонентский номер мобильного телефона белорусского оператора сотовой связи в международном формате, используемый Пользователем.
                                        <br/> <br/> 4. Адрес электронной почты Пользователя.
                                            <br/> <br/> 5. Игровые коды Пользователя.
                                            </span>
                                </div>
                                <span>1. Фамилия, имя, отчество (при наличии) Пользователя.
                                <br/> <br/>  2. Дата рождения Пользователя.
                                    <br/> <br/> 3. Абонентский номер мобильного телефона белорусского оператора сотовой связи в международном формате, используемый Пользователем.
                                        <br/> <br/> 4. Адрес электронной почты Пользователя.
                                            <br/> <br/> 5. Игровые коды Пользователя.
                                            </span>
                                <div className={styles.mobileTitle}>Цель
                                    <span>Участие Пользователя в Рекламной игре в соответствии с правилами Рекламной игры, размещенными на Сайте.</span>
                                </div>
                                <span>Участие Пользователя в Рекламной игре в соответствии с правилами Рекламной игры, размещенными на Сайте.</span>
                                <div className={styles.mobileTitle}>Основание
                                    <span>Согласие Пользователя на обработку его персональных данных в соответствии с Политикой, предоставляемое путем проставления соответствующей отметки в электронной форме согласия на обработку персональных данных после ознакомления Пользователя с обязательной информацией об обработке его персональных данных и перед регистрацией Пользователя на Сайте.</span>
                                </div>
                                <span>Согласие Пользователя на обработку его персональных данных в соответствии с Политикой, предоставляемое путем проставления соответствующей отметки в электронной форме согласия на обработку персональных данных после ознакомления Пользователя с обязательной информацией об обработке его персональных данных и перед регистрацией Пользователя на Сайте.</span>
                                <div className={styles.mobileTitle}>Срок
                                    <span>С момента регистрации на Сайте до окончания проведения Рекламной игры (по 08.12.2024).</span>
                                </div>
                                <span>С момента регистрации на Сайте до окончания проведения Рекламной игры (по 08.12.2024).</span>
                            </div>
                        </div>

                        <p>5. Персональные данные, указанные в пунктах 1–4 таблицы 1 выше предоставляются Компании
                            Пользователем
                            путем
                            их внесения в соответствующие поля формы регистрации при создании личного кабинета
                            Пользователя
                            на
                            Сайте.</p>
                        <p>6. В целях обеспечения участия Пользователей в Рекламной игре, в частности для формирования
                            списков
                            участников Рекламной игры, проведения розыгрышей призов и публикации результатов розыгрышей
                            с
                            указанием
                            фамилий и инициалов победителей, информирования участников Рекламной игры о выигрышах,
                            Компания
                            осуществляет
                            с персональными данными Пользователей следующие действия: сбор, систематизация, хранение,
                            копирование,
                            уточнение (обновление, изменение), извлечение, использование, передача (распространение,
                            предоставление),
                            блокирование, уничтожение (удаление).</p>

                        <h2>ГЛАВА 3. СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
                        <p>7. Согласие Пользователя на обработку Компанией его персональных данных в объеме и целях, на
                            срок
                            и
                            условиях,
                            предусмотренных Политикой (далее – Согласие) предоставляется путем проставления
                            соответствующей
                            отметки
                            в
                            электронной форме согласия на обработку персональных данных после ознакомления Пользователя
                            с
                            обязательной
                            информацией об обработке его персональных данных и перед регистрацией Пользователя на
                            Сайте.</p>
                        <p>8. Согласие Пользователя предоставляется на срок до окончания проведения Рекламной игры (по
                            08.12.2024).
                            После истечения указанного срока Компания прекращает обработку всех персональных данных
                            Пользователей,
                            указанных в пункте 4 Политики, посредством их удаления.</p>
                        <p>9. В случае отказа Пользователя в предоставлении Согласия (отсутствия соответствующей отметки
                            Пользователя в
                            форме регистрации) регистрация Пользователя на Сайте и его участие в Рекламной игре
                            становятся
                            невозможным.</p>
                        <p>10. Пользователь в любое время может отозвать ранее предоставленное им Согласие посредством
                            направления
                            Компании заявления в порядке, установленном пунктом 18 Положения.</p>
                        <p>11. В случае отзыва Пользователем ранее предоставленного Согласия, личный кабинет
                            Пользователя и
                            все
                            его
                            персональные данные, обрабатываемые Компанией в соответствии с Политикой, подлежат удалению
                            в
                            течение 15
                            (пятнадцати) календарных дней, следующих за днем получения Компанией соответствующего
                            заявления
                            Пользователя, что делает невозможным дальнейшее участие Пользователя в Рекламной игре.</p>

                        <h2>ГЛАВА 4. ПРЕДОСТАВЛЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ ТРЕТЬИМ ЛИЦАМ</h2>
                        <p>12. Компания передает персональные данные Пользователей следующему ограниченному кругу
                            уполномоченных
                            лиц
                            Компании:</p>
                        <ul>
                            <li>общество с ограниченной ответственностью «ЭфСиБи Бел» (местонахождение: пер. Козлова 25,
                                пом.
                                22,
                                220037, Минск; УНП 193185741) – юридическое лицо, оказывающее Компании на основании
                                гражданско-правового
                                договора услуги по организации и проведению Рекламной игры (далее – Организатор
                                Рекламной
                                игры);
                            </li>
                            <li>общество с ограниченной ответственностью «Рэди Гоу» (местонахождение: ул. Кальварийская,
                                д.21,
                                оф.212,
                                220004, Минск; УНП 800009621) – юридическое лицо, оказывающее Компании на основании
                                гражданско-правового
                                договора услуги по техническому и информационному сопровождению работы Сайта;
                            </li>
                            <li>общество с ограниченной ответственностью «Надежные программы» местонахождение: ул.
                                Аранская,
                                8,
                                блок
                                1,
                                4 этаж, 220006, Минск; УНП 100160363) – юридическое лицо, оказывающее Компании на
                                основании
                                гражданско-правового договора услуги по предоставление в пользование и администрированию
                                информационно-технических ресурсов, а также услуги технической и криптографической
                                защите
                                информации,
                                необходимые для Сайта.
                            </li>
                        </ul>
                        <p>13. Уполномоченные лица, перечисленные в пункте 12 Политики выше, несут ответственность перед
                            Компанией
                            за
                            соблюдение требований законодательства в области защиты персональных данных и Политики при
                            обработке
                            ими
                            персональных данных Пользователей, переданных Компанией, а также правовых, организационных и
                            технических
                            мер
                            по их защите, предусмотренных гражданско-правовыми договорами между ними и Компанией.</p>
                        <p>14. Фамилии и инициалы Пользователей, которые в соответствии с правилами Рекламной игры
                            признаны
                            победителями
                            Рекламной игры, в силу требований Положения о проведении рекламных игр на территории
                            Республики
                            Беларусь,
                            утвержденного Указа Президента Республики Беларусь от 30.01.2003 № 51 (далее – Положение),
                            подлежат
                            опубликованию (доведены до всеобщего сведения) в средстве массовой информации.</p>
                        <p>15. Организатор Рекламной игры в силу требований Положения после окончания Рекламной игры
                            обязан
                            направить в
                            Министерство антимонопольного регулирования и торговли Республики Беларусь отчет о
                            проведении
                            Рекламной
                            игры, а также хранить все материалы по Рекламной игре в течение 3 (трех) лет со дня ее
                            окончания. В
                            указанных случаях Компания не имеет отношения к обработке персональных данных Пользователей,
                            содержащихся в
                            отчете и материалах по Рекламной игре, которая осуществляется Организатором Рекламной игры
                            как
                            оператором на
                            основании Положения без необходимости получения соответствующих Согласий.</p>

                        <h2>ГЛАВА 5. ПРАВА ПОЛЬЗОВАТЕЛЕЙ И МЕХАНИЗМ ИХ РЕАЛИЗАЦИИ</h2>
                        <p>16. Права Пользователей, связанные с обработкой их персональных данных, и механизм их
                            реализации
                            изложены
                            в
                            таблице 2 ниже.</p>

                        <p className={styles.tabsTitle}>Таблица 2. Права Пользователей, связанные с обработкой их
                            персональных данных, и механизм их
                            реализации</p>
                        <div className={styles.table}>
                            <div className={styles.tableTitle}>
                                <p>Право</p>
                                <p>Содержание</p>
                                <p>Порядок реализации</p>
                            </div>
                            <div className={styles.tableSubtitle}>
                                <div className={styles.mobileTitle}>
                                    <p>Право</p>
                                    <span>1. Право на получение информации об обработке персональных данных.</span>
                                </div>
                                <div className={styles.mobileTitle}>
                                    <p>Содержание</p>
                                    <span>Пользователь вправе получить информацию о том, какие его персональные данные, для чего, на каком основании и каким образом Компания собирает и использует, в том числе информацию о Компании как об операторе и ее уполномоченных лицах, о сроке Согласия. Пользователь не должен обосновывать свой интерес к запрашиваемой информации.</span>
                                </div>
                                <div className={styles.mobileTitle}>
                                    <p>Порядок реализации</p>
                                    <span>В течение 5 (пяти) рабочих дней после получения заявления Пользователя Компания предоставляет Пользователю запрашиваемую информацию или уведомление о причинах отказа в ее предоставлении в письменном виде.</span>
                                </div>
                                <span>1. Право на получение информации об обработке персональных данных.</span>
                                <span>Пользователь вправе получить информацию о том, какие его персональные данные, для чего, на каком основании и каким образом Компания собирает и использует, в том числе информацию о Компании как об операторе и ее уполномоченных лицах, о сроке Согласия. Пользователь не должен обосновывать свой интерес к запрашиваемой информации.</span>
                                <span>В течение 5 (пяти) рабочих дней после получения заявления Пользователя Компания предоставляет Пользователю запрашиваемую информацию или уведомление о причинах отказа в ее предоставлении в письменном виде.</span>
                            </div>
                            <div className={styles.tableSubtitle}>
                                <div className={styles.mobileTitle}>
                                    <p>Право</p>
                                    <span>2. Право на получение информации о предоставлении персональных данных третьим лицам.</span>
                                </div>
                                <div className={styles.mobileTitle}>
                                    <p>Содержание</p>
                                    <span>Пользователь вправе получать информацию о том, какие третьи лица и на каком основании имеют доступ к его персональным данным. Один раз в календарный год такая информация предоставляется бесплатно.</span>
                                </div>
                                <div className={styles.mobileTitle}>
                                    <p>Порядок реализации</p>
                                    <span>В течение 15 (пятнадцати) календарных дней после получения заявления Пользователя Компания предоставляет Пользователю запрашиваемую информацию или уведомление о причинах отказа в ее предоставлении в письменном виде.</span>
                                </div>
                                <span>2. Право на получение информации о предоставлении персональных данных третьим лицам.</span>
                                <span>Пользователь вправе получать информацию о том, какие третьи лица и на каком основании имеют доступ к его персональным данным. Один раз в календарный год такая информация предоставляется бесплатно.</span>
                                <span>В течение 15 (пятнадцати) календарных дней после получения заявления Пользователя Компания предоставляет Пользователю запрашиваемую информацию или уведомление о причинах отказа в ее предоставлении в письменном виде.</span>
                            </div>
                            <div className={styles.tableSubtitle}>
                                <div className={styles.mobileTitle}>
                                    <p>Право</p>
                                    <span>3. Право на внесение изменений в персональные данные.</span>
                                </div>
                                <div className={styles.mobileTitle}>
                                    <p>Содержание</p>
                                    <span>Компания не проверяет достоверность персональных данных, предоставляемых Пользователем. Тем не менее, в любой момент Пользователь вправе требовать от Компании внести изменения в его персональные данные в случаях, если они являются неполными, неточными или утратили актуальность. К заявлению Пользователю следует приложить документы, подтверждающие необходимость внесения изменений в персональные данные.</span>
                                </div>
                                <div className={styles.mobileTitle}>
                                    <p>Порядок реализации</p>
                                    <span>В течение 15 (пятнадцати) календарных дней после получения заявления Пользователя Компания внесет соответствующие изменения в персональные данные Пользователя.</span>
                                </div>
                                <span>3. Право на внесение изменений в персональные данные.</span>
                                <span>Компания не проверяет достоверность персональных данных, предоставляемых Пользователем. Тем не менее, в любой момент Пользователь вправе требовать от Компании внести изменения в его персональные данные в случаях, если они являются неполными, неточными или утратили актуальность. К заявлению Пользователю следует приложить документы, подтверждающие необходимость внесения изменений в персональные данные.</span>
                                <span>В течение 15 (пятнадцати) календарных дней после получения заявления Пользователя Компания внесет соответствующие изменения в персональные данные Пользователя.</span>
                            </div>
                            <div className={styles.tableSubtitle}>
                                <div className={styles.mobileTitle}>
                                    <p>Право</p>
                                    <span>4. Право на прекращение обработки персональных данных или их удаление.</span>
                                </div>
                                <div className={styles.mobileTitle}>
                                    <p>Содержание</p>
                                    <span>Пользователь вправе требовать от Компании бесплатного прекращения обработки его персональных данных, включая их удаления при отсутствии (утрате) оснований для обработки таких данных.</span>
                                </div>
                                <div className={styles.mobileTitle}>
                                    <p>Порядок реализации</p>
                                    <span>В течение 15 (пятнадцати) календарных дней после получения заявления Пользователя Компания прекратит обработку персональных данных Пользователя за исключением случаев, когда Компания вправе продолжить обработку персональных данных при наличии оснований, установленных законодательством Республики Беларусь.</span>
                                </div>
                                <span>4. Право на прекращение обработки персональных данных или их удаление.</span>
                                <span>Пользователь вправе требовать от Компании бесплатного прекращения обработки его персональных данных, включая их удаления при отсутствии (утрате) оснований для обработки таких данных.</span>
                                <span>В течение 15 (пятнадцати) календарных дней после получения заявления Пользователя Компания прекратит обработку персональных данных Пользователя за исключением случаев, когда Компания вправе продолжить обработку персональных данных при наличии оснований, установленных законодательством Республики Беларусь.</span>
                            </div>
                            <div className={styles.tableSubtitle}>
                                <div className={styles.mobileTitle}>
                                    <p>Право</p>
                                    <span>5. Право на отзыв согласия на обработку персональных данных</span>
                                </div>
                                <div className={styles.mobileTitle}>
                                    <p>Содержание</p>
                                    <span>Если Согласие Пользователя является правовым основанием для обработки Компанией его персональных данным, Пользователь может в любое время отозвать его. Пользователь может отозвать согласие без объяснения причин. Это не повлияет на законность основания обработки персональных данных Пользователя до момента отзыва Согласия.</span>
                                </div>
                                <div className={styles.mobileTitle}>
                                    <p>Порядок реализации</p>
                                    <span>В течение 15 (пятнадцати) календарных дней после получения заявления Пользователя или удаления своего личного кабинета на Сайте Компания прекратит обработку персональных данных Пользователя, осуществит их удаление и уведомит Пользователя об этом. При отсутствии технической возможности удаления персональных данных Компания примет меры по недопущению дальнейшей обработки, уведомив Пользователя об этом. Исключение составляют случаи, когда Компания или иной оператор вправе продолжить обработку персональных данных при наличии оснований, установленных законодательством Республики Беларусь.</span>
                                </div>
                                <span>5. Право на отзыв согласия на обработку персональных данных</span>
                                <span>Если Согласие Пользователя является правовым основанием для обработки Компанией его персональных данным, Пользователь может в любое время отозвать его. Пользователь может отозвать согласие без объяснения причин. Это не повлияет на законность основания обработки персональных данных Пользователя до момента отзыва Согласия.</span>
                                <span>В течение 15 (пятнадцати) календарных дней после получения заявления Пользователя или удаления своего личного кабинета на Сайте Компания прекратит обработку персональных данных Пользователя, осуществит их удаление и уведомит Пользователя об этом. При отсутствии технической возможности удаления персональных данных Компания примет меры по недопущению дальнейшей обработки, уведомив Пользователя об этом. Исключение составляют случаи, когда Компания или иной оператор вправе продолжить обработку персональных данных при наличии оснований, установленных законодательством Республики Беларусь.</span>
                            </div>
                            <div className={styles.tableSubtitle}>
                                <div className={styles.mobileTitle}>
                                    <p>Право</p>
                                    <span>6. Право на обжалование наших действий</span>
                                </div>
                                <div className={styles.mobileTitle}>
                                    <p>Содержание</p>
                                    <span>Если Пользователь считает, что действия Компании по обработке его персональных данных нарушают законодательство или права Пользователя, Пользователь может подать жалобу в уполномоченный орган.</span>
                                </div>
                                <div className={styles.mobileTitle}>
                                    <p>Порядок реализации</p>
                                    <span>Жалоба подается в Национальный центр по защите персональных данных Республики Беларусь в электронной или письменной форме. Срок рассмотрения жалобы – 1 (один) месяц со дня, следующего за днем ее регистрации. Порядок обжалования установлен Положением о Национальном центре защиты персональных данных, утвержденным Указом Президента Республики Беларусь от 28.10.2021 № 422.</span>
                                </div>
                                <span>6. Право на обжалование наших действий</span>
                                <span>Если Пользователь считает, что действия Компании по обработке его персональных данных нарушают законодательство или права Пользователя, Пользователь может подать жалобу в уполномоченный орган.</span>
                                <span>Жалоба подается в Национальный центр по защите персональных данных Республики Беларусь в электронной или письменной форме. Срок рассмотрения жалобы – 1 (один) месяц со дня, следующего за днем ее регистрации. Порядок обжалования установлен Положением о Национальном центре защиты персональных данных, утвержденным Указом Президента Республики Беларусь от 28.10.2021 № 422.</span>
                            </div>
                        </div>
                        <p>17. Для реализации своих прав, указанных в пункте 16 Положения, Пользователь подает
                            (направляет)
                            в
                            Компанию
                            заявление в письменной форме по почтовому адресу Компании: деревня Колядичи,147/2, Сеницкий
                            сельсовет,
                            Минский район, Минская область. Такое заявление должно содержать:</p>
                        <ul>
                            <li>фамилию, собственное имя, отчество (если таковое имеется) Пользователя;</li>
                            <li>адрес места жительства (места пребывания) Пользователя;</li>
                            <li>дату рождения Пользователя;</li>
                            <li>изложение сути требований Пользователя;</li>
                            <li>личную подпись Пользователя</li>
                        </ul>
                        <p>Компания не рассматривает заявления Пользователей, направленные иными способами (e-mail,
                            телефон,
                            факс и
                            т.п).</p>
                        <p>При этом право отзыва ранее предоставленного согласия на обработку персональных данных может
                            быть
                            реализовано
                            в электронной форме посредством удаления субъектом персональных данных своего личного
                            кабинета
                            на
                            Сайте.</p>

                        <h2>ГЛАВА 6. МЕРЫ ПО ОБЕСПЕЧЕНИЮ ЗАЩИТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
                        <p>18. Компания принимает правовые, организационные и технические меры для обеспечения защиты
                            персональных
                            данных Пользователей от несанкционированного или случайного доступа к ним, изменения,
                            блокирования,
                            копирования, распространения, предоставления, удаления персональных данных, а также от иных
                            неправомерных
                            действий.</p>
                        <p>19. В целях защиты персональных данных Компания, в частности, приняла следующего меры:</p>
                        <ul>
                            <li>назначила работника, ответственного за осуществление внутреннего контроля за обработкой
                                персональных
                                данных, и обеспечила освоение им образовательной программы повышения квалификации
                                руководящего
                                работника
                                (специалиста) по учебной программе «Защита персональных данных» Национального центра
                                защиты
                                персональных
                                данных Республики Беларусь;
                            </li>
                            <li>издала комплекс документов, определяющих политику Компании в отношении обработки
                                персональных
                                данных, в
                                соответствии с рекомендациями Национального центра защиты персональных данных Республики
                                Беларусь;
                            </li>
                            <li>ознакомила работников Компании с положениями законодательства о защите персональных
                                данных,
                                документами,
                                определяющими политику Компании в отношении обработки персональных данных, и внесла
                                соответствующие
                                обязанности в должностные (рабочие) инструкции;
                            </li>
                            <li>осуществляет техническую и криптографическую защиты персональных данных Пользователей
                                посредством
                                системы защиты информации Сайта, аттестованной в установленном законодательстве порядке;
                            </li>
                            <li>обязала уполномоченных лиц осуществлять обработку персональных данных Пользователей с
                                соблюдением
                                требований законодательства в области защиты персональных данных и Политики, а также с
                                обязательным
                                принятием правовых, организационных и технических мер по их защите персональных данных
                                Пользователей,
                                предусмотренных гражданско-правовыми договорами между ними и Компанией, и установила
                                существенную
                                ответственность за нарушение данного обязательства.
                            </li>
                        </ul>

                        <h2>ГЛАВА 7. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h2>
                        <p>20. Вопросы, касающиеся обработки персональных данных, не урегулированные Политикой,
                            регулируются
                            законодательством Республики Беларусь.</p>
                        <p>21. В случае, если какое-либо положение Политики признается противоречащим законодательству
                            Республики
                            Беларусь, остальные положения, соответствующие законодательству Республики Беларусь,
                            остаются в
                            силе
                            и
                            являются действительными, а любое недействительное положение будет считаться удаленным
                            (измененным)
                            в
                            той
                            мере, в какой это необходимо для обеспечения его соответствия законодательству Республики
                            Беларусь.</p>
                        <p>22. Компания имеет право по своему усмотрению изменять и (или) дополнять условия Политики без
                            предварительного и (или) последующего уведомления Пользователей. Действующая редакция
                            Политики
                            постоянно
                            доступна на Сайте.</p>
                    </div>
                </div>
            </div>
            <FooterPublic/>
        </div>
    )
        ;
}