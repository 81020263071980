import React from 'react';
import styles from './css/Rules.module.css';
import image10 from '../../img/image10.svg';

export const Rules = () => {
    return (
        <section id="rules" className={styles.rules} style={{ backgroundImage: `url(${image10})` }}>
            <div className={styles.container}>
                <div className="start-block h c sb">
                    <h2 className="start">Как участвовать в рекламной игре?</h2>
                    <div className={styles.buttons}>
                        <a href="/" className={styles.rulesButton}>Правила</a>
                        <a href="#form" className={styles.participateButton}>Участвовать</a>
                    </div>
                </div>

                <div className={styles.cards}>
                    <div className={styles.card}>
                        <div className={styles.step}>1</div>
                        <h3>Покупайте игровые продукты</h3>
                        <p>в магазинах Корона</p>
                    </div>
                    <div className={styles.card}>
                        <div className={styles.step}>2</div>
                        <h3>Регистрируйтесь на сайте</h3>
                        <p>сoca-cola-promo.by</p>
                    </div>
                    <div className={styles.card}>
                        <div className={styles.step}>3</div>
                        <h3>Загружайте фотографию чека</h3>
                        <p>на сайт через личный кабинет</p>
                    </div>
                    <div className={styles.card}>
                        <div className={styles.step}>4</div>
                        <h3>Получите шанс выиграть призы</h3>
                        <p>в еженедельных розыгрышах</p>
                    </div>
                </div>

                <h3 className="recipe">Сохраняйте чек до окончания рекламной игры, чтобы забрать свой приз</h3>
            </div>
        </section>
    );
};
