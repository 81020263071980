import React from 'react';
import styles from './css/Prizes.module.css';
import image16 from '../../img/image16.jpg';
import image17 from '../../img/image17.png';
import image18 from '../../img/image18.png';
import image19 from '../../img/image19.png';
import image20 from '../../img/image20.png';
import image15 from "../../img/image15.svg";

export const Prizes = () => {
    return (
        <section id="prizes" className={styles.prizes} style={{ backgroundImage: `url(${image15})` }}>
            <div className={styles.container}>
                <h2 className={styles.title}>Призы</h2>
                <div className={styles.prizeImage}>
                    <span>Главный приз</span>
                    <img src={image16} alt="Prize"/>
                    <div className={styles.prizesText}>
                        <h3>Автомобиль Nissan X-Trail</h3>
                        <p>Розыгрыш 4 октября 2024 года в 16:00</p>
                    </div>
                </div>
                <div className={styles.prizeBlock}>
                    <div className={styles.prizeCard}>
                        <span>48 призов</span>
                        <img src={image17} alt="grille"/>
                        <h3>Электрогриль</h3>
                        <p className={styles.subtitle}>Redmond SteakMaster RGM-M817D</p>
                        <p className={styles.frequency}>6 призов разыгрываются еженедельно</p>
                    </div>
                    <div className={styles.prizeCard}>
                        <span>80 призов</span>
                        <img src={image18} alt="grille"/>
                        <h3>Портативная колонка</h3>
                        <p className={styles.subtitle}>Sven PS-380</p>
                        <p className={styles.frequency}>10 призов разыгрываются еженедельно</p>
                    </div>
                    <div className={styles.prizeCard}>
                        <span>86 призов</span>
                        <img src={image19} alt="grille"/>
                        <h3>Пледы и термосумки</h3>
                        <p className={styles.frequency}>12 призов разыгрываются еженедельно</p>
                    </div>
                    <div className={styles.prizeCard}>
                        <span>520 призов</span>
                        <img src={image20} alt="grille"/>
                        <h3>Сертификаты «Корона»</h3>
                        <p className={styles.subtitle}>на сумму 100 рублей</p>
                        <p className={styles.frequency}>65 призов разыгрываются еженедельно</p>
                    </div>
                </div>
            </div>
        </section>
    );
};
