import React from 'react';
import styles from './css/Terms.module.css';
import {FooterPublic} from "../shared/Footer";
import {Introduction} from "./Introduction";

export const Terms = () => {
    return (<>
            <Introduction/>
            <div className={styles.public}>
                <div className={styles.container1}>
                    <div className={styles.text}>
                        <h1 className={styles.title}>Условия использования интернет-сайта coca-cola-promo.by</h1>
                        <p className={styles.subtitle}>ПОСЛЕДНЯЯ РЕДАКЦИЯ 01.12.2023</p>

                        <div className={styles.textBlock}>
                            <p>Доступ и пользование интернет-сайтом coca-cola-promo.by, который включает в себя
                                совокупность
                                программных средств и текстовой, графической и иной информации, (далее – «Сайт»)
                                регулируются
                                настоящими условиями пользования (далее — «Условия пользования»), а также
                                законодательством
                                Республики Беларусь.</p>
                            <p>Если вы не согласны с настоящими Условиями пользования и не принимаете их полностью без
                                каких-либо
                                изъятий и оговорок, просим вас покинуть Сайт.</p>
                            <p>Под пользователями в настоящих Условиях использования понимаются лица, посетившие Сайт
                                вне
                                зависимости от того, зарегистрированы ли они на Сайте или нет.</p>

                            <h2>1. Соблюдение авторских и иных прав</h2>
                            <p>Права на объекты интеллектуальной собственности, размещенные на Сайте (далее —
                                «Содержание»),
                                принадлежат производственному унитарному предприятию «Кока-Кола Бевриджиз Белоруссия»
                                (далее
                                –
                                Компания), The Coca-Cola Company или иным третьим лицам – контрагентам Компании и
                                охраняются
                                государством в соответствии с законодательством. Любое Содержание, представляющее собой
                                товарный
                                знак или знак обслуживания, является зарегистрированным товарным знаком. С учетом
                                исключений,
                                предусмотренных настоящими Условиями пользования, использование любой части Содержания
                                без
                                письменного разрешения Компании или иного правообладателя запрещено. Компания следит за
                                соблюдением прав на объекты интеллектуальной собственности и иных прав. Нарушение прав
                                влечет
                                ответственность в соответствии с законодательством Республики Беларусь.</p>

                            <h2>2. Порядок пользования Сайтом</h2>
                            <p>При пользовании Сайтом:</p>
                            <ul>
                                <li>Вы должны соблюдать действующее законодательство Республики Беларусь.</li>
                                <li>Вы не должны копировать (за исключением случаев, предусмотренных настоящими
                                    Условиями
                                    пользования), воспроизводить, публиковать, распространять, перерабатывать,
                                    передавать,
                                    переводить или иным образом использовать Содержание без письменного разрешения
                                    Компании;
                                </li>
                                <li>Разрешается копирование размещенных на Сайте объектов интеллектуальной собственности
                                    только
                                    при
                                    совокупности следующих условий: (i) возможность копирования прямо указана на Сайте;
                                    (ii)
                                    скопированные объекты интеллектуальной собственности будут использоваться в
                                    некоммерческих,
                                    личных целях; (iii) скопированные объекты интеллектуальной собственности будут
                                    использоваться со
                                    знаками охраны авторских и иных прав Компании, иных лиц, как они указаны на Сайте;
                                </li>
                                <li>Не допускается размещать или передавать любые материалы, нарушающие права на
                                    использование
                                    объектов интеллектуальной собственности, материалы, содержащие угрозы, недостоверные
                                    сведения,
                                    оскорбления, приставания, порнографические материалы, материалы, выражающие
                                    неуважение к
                                    конкретному человеку, группе людей или обществу в целом, нарушающие общественный
                                    порядок
                                    или
                                    любые иные материалы, которые могут представлять собой или стимулировать поведение,
                                    которое
                                    является неправомерным и нарушающим действующее законодательство Республики
                                    Беларусь.
                                    Компания
                                    будет оказывать полное содействие правоохранительным органам и выполнять любые
                                    принятые
                                    в
                                    соответствии с законодательными актам Республики Беларусь судебные и
                                    административные
                                    акты,
                                    предписывающие Компании сообщить уполномоченным государственным органам персональные
                                    данные
                                    пользователя, разместившего или передавшего любые подобные сведения или материалы.
                                </li>
                                <li>Запрещается выдавать себя на Сайте за какое-либо другое лицо, в том числе за
                                    модератора
                                    Сайта
                                    или представителя Компании, а также делать ложные заявления или представлять
                                    искаженные
                                    сведения
                                    о своих отношениях с лицами, связанными с Сайтом, или утверждать или намекать на то,
                                    что
                                    Компания поддерживает какие-либо Ваши заявления и (или) высказывания;
                                </li>
                                <li>Не допускается использование Сайта в рекламных целях или для осуществления действия,
                                    направленного на получение прибыли в каком-либо виде (и/или форме), а также иная
                                    коммерческая
                                    эксплуатация любой части Сайта, его использования или права доступа к нему.
                                </li>
                                <li>Не допускается использование Сайта для размещения или передачи шпионских и/или
                                    вредоносных
                                    программ и других компьютерных кодов, файлов или программ, которые могут причинить
                                    вред,
                                    носят
                                    характер вторжения или созданы с целью повреждения или незаконного получения
                                    информации,
                                    незаконного использования Содержания, или контроля за использованием, любой
                                    аппаратуры,
                                    программного обеспечения или оборудования;
                                </li>
                                <li>Запрещается использовать Сайт с целью нарушения прав других лиц, а также обработки
                                    персональных
                                    данных пользователей Сайта;
                                </li>
                                <li>Запрещается ограничивать или препятствовать использованию Сайта другими лицами,
                                    нарушать
                                    работу
                                    Сайта или серверов и сетей, обеспечивающих работу Сайта, а также нарушать
                                    требования,
                                    процедуры,
                                    правила и нормы работы этих сетей.
                                </li>
                            </ul>

                            <h2>3. Регистрация, имя пользователя и пароль</h2>
                            <p>Для доступа к определенным участкам Сайта Вам может потребоваться регистрация на Сайте.
                                Во
                                время
                                регистрации Компания может отказать Вам в использовании имени пользователя (или адреса
                                электронной
                                почты), которое уже выбрано другим лицом, и (или) может расцениваться как попытка выдать
                                себя за
                                другое лицо, и (или) принадлежит другому лицу, и (или) нарушает права на результаты
                                интеллектуальной
                                деятельности, и (или) прочие права других лиц, и (или) является оскорбительным. Вы
                                отвечаете
                                за
                                сохранение в тайне пароля доступа к Сайту и обязуетесь не передавать пароль и имя
                                пользователя
                                или
                                иным образом передавать пользование или доступ к Сайту другим лицам. Вы несете полную
                                ответственность за взаимодействие с Сайтом под вашим паролем и именем пользователя. Вы
                                обязуетесь
                                немедленно уведомлять Компанию в случае несанкционированного использования вашей учетной
                                записи
                                или
                                имени пользователя, а также о других нарушений безопасности в отношении вашей учетной
                                записи
                                или
                                Сайта и, кроме того, выходить из своей учетной записи на Сайте по завершении каждого
                                посещения
                                Сайта. Компания не несет ответственность за убытки и ущерб, понесенные вследствие
                                невыполнения
                                Вами
                                каких-либо из вышеперечисленных обязательств.</p>

                            <h2>4. Правила проведения рекламных мероприятий</h2>
                            <p>Все рекламные игры и иные рекламные мероприятия, организуемые Компанией или третьими
                                лицами –
                                контрагентами Компании –и проводимые с использованием в каком-либо виде Сайта,
                                регулируются
                                особыми
                                правилами и условиями, отличными от данных Условий, но в любом случае с учетом
                                требований
                                законодательства Республики Беларусь. Правила и условия проведения рекламных игр и иных
                                рекламных
                                мероприятий публикуются на Сайте и (или) иным образом в порядке, предусмотренным
                                законодательством,
                                Компанией или организатором таких мероприятий. Участвуя в рекламных играх или иных
                                рекламных
                                мероприятиях, Вы должны соблюдать эти правила, которые могут отличаться от условий,
                                изложенных в
                                данном документе. Компания настоятельно рекомендует Вам ознакомиться со всеми правилами
                                и
                                условиями
                                каждого конкретного мероприятия.</p>

                            <h2>5. Конфиденциальность информации</h2>
                            <p>В отношении персональных данных, предоставляемых Компании пользователями посредством
                                Сайта,
                                Компания
                                обязуется придерживается требований законодательства Республики Беларусь и локальных
                                правовых
                                актов
                                в отношении использования (включая хранение) персональных данных. Вы заявляете и
                                гарантируете,
                                что
                                вся информация, предоставляемая Вами в связи с использованием Сайта, является в
                                настоящее
                                время
                                подлинной, точной и полной, а также обязуетесь поддерживать и регулярно обновлять эту
                                информацию. Вы
                                соглашаетесь с тем, что, если предоставленная Вами информация окажется ложной, неточной,
                                устаревшей
                                или неполной, мы можем отказать Вам в использовании Сайта на неопределенный срок.</p>

                            <h2>6. Внесение изменений в Условия пользования</h2>
                            <p>Компания может в любое время без предварительного уведомления вносить изменения в
                                настоящие
                                Условия
                                пользования посредством обновления данного документа. Так как действие пересмотренных
                                документов
                                распространяется на Вас, то Вам следует периодически посещать данную страницу и
                                просматривать
                                Условия пользования. Дата последней редакции Условий использования указывается под
                                надписью
                                «ПОСЛЕДНЯЯ РЕДАКЦИЯ» в верхней части документа.</p>

                            <h2>7. Применимое право и порядок разрешения споров</h2>
                            <p>Настоящие Условия пользования регулируются законодательством Республики Беларусь. В
                                случае
                                возникновения спора Компания и пользователь должны предпринять все меры для разрешения
                                путем
                                переговоров любых споров и противоречий, которые могут возникнуть между ними. В случае,
                                если
                                Стороны
                                не смогут путем переговоров разрешить спор или противоречия между ними, такие споры и
                                противоречия
                                должны быть переданы на разрешение в судебном порядке в соответствии с действующим
                                законодательством.</p>

                            <h2>8. Делимость положений Условий пользования</h2>
                            <p>Недействительность или невозможность принудительного исполнения какого-либо положения
                                Условий
                                пользования не влияет на действительность и возможность принудительного исполнения
                                остальных
                                положений Условий пользования.</p>
                        </div>
                    </div>
                </div>
            </div>
            <FooterPublic/>
        </>
    );
}