import React from 'react';
import styles from './css/Contacts.module.css';
import {FooterPublic} from "../shared/Footer";
import {Introduction} from "./Introduction";
import image100 from "../../img/image100.svg";

export const Contacts = () => {
    return (
        <div className={styles.public}>
            <Introduction/>
            <section className={styles.contacts} style={{backgroundImage: `url(${image100})`}}>
                <div className={styles.contact}>
                    <h2 className={styles.title}>Контакты</h2>
                    <p className={styles.subtitle}>Если возникли вопросы, связанные с участием в Рекламной игре, напиши нам:
                        <a href="mailto:info@coca-cola-promo.by">info@coca-cola-promo.by</a>
                    </p>
                    <p>Или позвони по номеру информационной линии:
                        <a href="tel:+ 111 111 11 11">+375 44 778 11 00</a>
                    </p>
                </div>
            </section>
            <FooterPublic/>
        </div>
    );
}