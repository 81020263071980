import React from 'react';
import {Link, Route, Routes} from "react-router-dom";
import styles from './css/Client.module.css';
import {HeaderClient} from "../shared/Header";
import {Receipts} from "./Receipts";
import {FooterPublic} from "../shared/Footer";
import {Profile} from "./Profile";
import {UploadReceipt} from "./UploadReceipt";

export const Client = () => {
    return (
        <div className={styles.client}>
            <HeaderClient/>
            <section className={styles.public}>
                <div className={styles.container}>
                    <h1 className={styles.h1}>Личный профиль</h1>
                    <p className={styles.subtitle}>Загрузить чеки можно до 30 сентября</p>
                    <div className={styles.block}>
                        <div className={styles.menu}>
                            <Link to={'/client'}>Мой профиль</Link>
                            <Link to={'/client/receipts/add'}>Загрузка чека</Link>
                            <Link to={'/client/receipts'}>Мои чеки</Link>
                        </div>
                        <div className={styles.content}>
                            <Routes>
                                <Route path="/" element={<Profile/>}/>
                                <Route path="/receipts" element={<Receipts/>}/>
                                <Route path="/receipts/add" element={<UploadReceipt/>}/>
                            </Routes>
                        </div>
                    </div>
                </div>
            </section>
            <FooterPublic/>
        </div>
    );
};