import React from 'react';
import styles from "./css/Receipts.module.css";

export const Receipts = () => {
    return (
        <section className={styles.receipts}>
            <h1 className={styles.h1}>Статус моих чеков</h1>

            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>Дата загрузки</th>
                        <th>Дата розыгрыша</th>
                        <th>Статус чека</th>
                        <th>Игровой код</th>
                        <th>Причина отклонения</th>
                        <th>Победа</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>01.01.2020</td>
                        <td>01.01.2021</td>
                        <td>Принят</td>
                        <td>00050775</td>
                        <td></td>
                        <td>Сертификат «Корона» на сумму 100 рублей</td>
                    </tr>
                    <tr>
                        <td>01.01.2020</td>
                        <td>01.01.2021</td>
                        <td>Отклонен</td>
                        <td>00050776</td>
                        <td>Отсутствует товар</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </section>
    );
};