import React from 'react';
import styles from './css/Items.module.css';
import image11 from '../../img/image11.png';
import image12 from '../../img/image12.png';
import image13 from '../../img/image13.png';
import image14 from '../../img/image14.png';

export const Items = () => {
    return (
        <section className={styles.items} id="items">
            <div className={styles.container}>
                <h2 className={styles.title}>Игровой продукт</h2>
                <div className={styles.tabs}>
                    <span>Coca-Cola</span>
                    <span>Bonaqua</span>
                    <span>Rich</span>
                    <span>Burn</span>
                    <span>Schweppes</span>
                </div>
                <div className={styles.itemContainer}>
                    <div className={styles.itemCard}>
                        <div className={styles.itemsImage}>
                            <img src={image11} alt="Cola"/>
                        </div>
                        <p>Напиток безалкогольный газированный «Кока-Кола» без сахара»: 0.33л, 0.5л, 1л, 1.5л, 2л</p>
                    </div>
                    <div className={styles.itemCard}>
                        <div className={styles.itemsImage}>
                            <img src={image12} alt="Cola"/>
                        </div>
                        <p>Напиток безалкогольный газированный «Кока-Кола»: 0.33л, 0.5л, 1л, 1.5л, 2л</p>
                    </div>
                    <div className={styles.itemCard}>
                        <div className={styles.itemsImage}>
                            <img src={image13} alt="Cola"/>
                        </div>
                        <p>Напиток безалкогольный газированный «Кока-Кола» Ванилла»: 0.33л, 0.5л, 1л, 1.5л</p>
                    </div>
                    <div className={styles.itemCard}>
                        <div className={styles.itemsImage}>
                            <img src={image14} alt="Cola"/>
                        </div>
                        <p>Напиток безалкогольный газированный «Кока-Кола» Лайм»: 0.33л, 0.5л, 1л, 1.5л</p>
                    </div>
                </div>
            </div>
        </section>
    );
};
