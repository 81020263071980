import React, {useState} from 'react';
import styles from './css/NewRaffle.module.css';
import {api} from "../../index";
import {useParams} from "react-router-dom";

export const NewRaffle = () => {
    const {projectUuid} = useParams();
    const [raffleName, setRaffleName] = useState('');
    const [raffleStartDate, setRaffleStartDate] = useState('');
    const [raffleEndDate, setRaffleEndDate] = useState('');

    const addRaffle = () => {
        fetch(`${api}/raffles/`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                projectUuid: projectUuid,
                name: raffleName,
                started: raffleStartDate,
                finished: raffleEndDate,
            })
        }).then(() => {
            window.history.back()
        }).catch((err) => {
            console.error('Error adding project:', err);
        });
    };

    return (
        <div className={styles.raffle}>
            <h1 className={styles.h1}>Новый розыгрыш</h1>
            <div className={styles.form}>
                <label className={styles.label} htmlFor="name">Название розыгрыша</label>
                <input className={styles.input} type="text" id="name" value={raffleName}
                       placeholder="Введите название розыгрыша"
                       onChange={(e) => setRaffleName(e.target.value)}/>
                <label className={styles.label} htmlFor="startDate">Дата старта</label>
                <input className={styles.input} type="date" id="startDate" value={raffleStartDate}
                       onChange={(e) => setRaffleStartDate(e.target.value)}/>
                <label className={styles.label} htmlFor="endDate">Дата завершения</label>
                <input className={styles.input} type="date" id="endDate" value={raffleEndDate}
                       onChange={(e) => setRaffleEndDate(e.target.value)}/>
                <div className={styles.controls}>
                    <button className={styles.button} type="button" onClick={() => window.history.back()}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                             fill="#000">
                            <path
                                d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/>
                        </svg>
                        <div>Отменить</div>
                    </button>
                    <button className={[styles.button, styles.buttonPrimary].join(' ')} type="button"
                            onClick={addRaffle}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                             fill="#000">
                            <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/>
                        </svg>
                        <div>Add project</div>
                    </button>
                </div>
            </div>
        </div>
    );
};