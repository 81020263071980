import React from 'react';
import image3 from "../../img/image3.svg";
import image4 from "../../img/image4.png";
import image5 from "../../img/image5.svg";
import image6 from "../../img/image6.svg";
import image7 from "../../img/image7.svg";
import image8 from "../../img/image8.svg";
import image9 from "../../img/image9.svg";
import d44f6a24eb6d68bdcd5 from "../../img/5d44f6a24eb6d68bdcd5.jpg";
import styles from './css/Introduction.module.css';

export const Introduction = () => {
    return (
        <section className={styles.introduction}>
            <div className={styles.container}>
                <div className={styles.prizeContainer}>
                    <span className={styles.introductionText}>Принять участие можно с 1 августа по 30 сентября 2024 г.</span>
                    <img className={styles.image} src={image3} alt="picnic"/>
                    <div className={styles.mobileContainer}>
                        <div className={styles.cardContainer}>
                            <div className={styles.card}>
                                <img src={image5} alt="prize"/>
                                <span className={styles.cardText}>Автомобиль Nissan X-Trail</span>
                            </div>
                            <div className={styles.card}>
                                <img src={image6} alt="prize"/>
                                <span className={styles.cardText}>Беспроводная колонка</span>
                            </div>
                            <div className={styles.card}>
                                <img src={image7} alt="prize"/>
                                <span className={styles.cardText}>Электрогриль</span>
                            </div>
                            <div className={styles.card}>
                                <img src={image8} alt="prize"/>
                                <span className={styles.cardText}>Плед и сумка-холодильник</span>
                            </div>
                            <div className={styles.card}>
                                <img src={image9} alt="prize"/>
                                <span className={styles.cardText}>Сертификат "Корона"</span>
                            </div>
                        </div>
                        <img className={styles.mobileImage} src={d44f6a24eb6d68bdcd5} alt="prize"/>
                    </div>
                </div>
                <div className={styles.introductionImage}>
                    <img src={image4} alt="prize"/>
                </div>
            </div>
        </section>
    );
};
