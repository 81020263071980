import React, {useState} from 'react';
import styles from './css/FAQ.module.css';
import minus from "../../img/minus.svg";
import plus from "../../img/Plus.svg";

export const FAQ = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <section className={styles.faq} id="questions">
            <div className={styles.faqBlock}>
                <h2 className={styles.title}>Вопрос-ответ</h2>

                <div
                    className={`${styles.faqCard} ${openIndex === 0 ? styles.active : ''}`}
                    onClick={() => handleToggle(0)}
                >
                    <div className={styles.summary}>
                        <h2>Кто может принять участие в Рекламной игре?</h2>
                        <img
                            src={plus}
                            alt="plus"
                            className={styles.iconPlus}
                            style={{display: openIndex === 0 ? 'none' : 'block'}}
                        />
                        <img
                            src={minus}
                            alt="minus"
                            className={styles.iconMinus}
                            style={{display: openIndex === 0 ? 'block' : 'none'}}
                        />
                    </div>
                    {openIndex === 0 && (
                        <p>
                            К участию в Рекламной игре приглашаются физические лица – граждане Республики Беларусь,
                            достигшие 18 лет,
                            постоянно проживающие на территории Республики Беларусь, а также иностранные граждане и лица
                            без гражданства,
                            достигшие 18 лет, имеющие вид на жительство на территории Республики Беларусь и постоянно
                            проживающие в Республике Беларусь.
                        </p>
                    )}
                </div>

                <div
                    className={`${styles.faqCard} ${openIndex === 1 ? styles.active : ''}`}
                    onClick={() => handleToggle(1)}
                >
                    <div className={styles.summary}>
                        <h2>Где можно получить подробную информацию по проведению Рекламной игры?</h2>
                        <img
                            src={plus}
                            alt="plus"
                            className={styles.iconPlus}
                            style={{display: openIndex === 1 ? 'none' : 'block'}}
                        />
                        <img
                            src={minus}
                            alt="minus"
                            className={styles.iconMinus}
                            style={{display: openIndex === 1 ? 'block' : 'none'}}
                        />
                    </div>
                    {openIndex === 1 && (
                        <p>
                            Информацию о правилах и условиях участия в Рекламной игре можно получить на Интернет-сайте
                            Рекламной игры
                            www.coca-cola-promo.by, а также по телефону информационной линии: +375 44 778 11 00 в
                            рабочие дни с 10.00 до 17.00,
                            в субботу с 13.00 до 15.00 в период с 1 августа 2024 года по 8 декабря 2024 года (стоимость
                            звонка тарифицируется в
                            соответствии с тарифами мобильного оператора, услугами которого пользуется Участник.
                        </p>
                    )}
                </div>

                <div
                    className={`${styles.faqCard} ${openIndex === 2 ? styles.active : ''}`}
                    onClick={() => handleToggle(2)}
                >
                    <div className={styles.summary}>
                        <h2>Что нужно для участия в Рекламной игре?</h2>
                        <img
                            src={plus}
                            alt="plus"
                            className={styles.iconPlus}
                            style={{display: openIndex === 2 ? 'none' : 'block'}}
                        />
                        <img
                            src={minus}
                            alt="minus"
                            className={styles.iconMinus}
                            style={{display: openIndex === 2 ? 'block' : 'none'}}
                        />
                    </div>
                    {openIndex === 2 && (
                        <>
                            <p>Для участия в рекламной игре необходимо в период 1 августа 2024 года по 30 сентября 2024
                                года:</p>
                            <ol>
                                <li>Совершить покупку Игровых продуктов на сумму не менее 3,00 (Трех) белорусских рублей
                                    00
                                    копеек в
                                    одном Кассовом чеке в любом магазине «КОРОНА», расположенном на территории
                                    Республики
                                    Беларусь:
                                    <ul>
                                        <li>Напиток безалкогольный газированный «Кока-Кола», 1 л;</li>
                                        <li>Напиток безалкогольный газированный «Кока-Кола», 1,5 л;</li>
                                        <li>Напиток безалкогольный газированный «Кока-Кола», 2 л;</li>
                                        <li>Напиток безалкогольный газированный «Кока-Кола» без сахара», 0,5 л;</li>
                                        <li>Напиток безалкогольный газированный «Кока-Кола» без сахара», 1 л;</li>
                                        <li>Напиток безалкогольный газированный «Кока-Кола», 0,5 л;</li>
                                        <li>Напиток безалкогольный газированный «Кока-Кола» без сахара», 1,5 л;</li>
                                        <li>Напиток безалкогольный газированный «Кока-Кола» без сахара», 2 л;</li>
                                        <li>Напиток безалкогольный газированный «Кока-Кола» Ванилла», 0,5 л;</li>
                                        <li>Напиток безалкогольный газированный “Кока-Кола” Ванилла”, 1 л;</li>
                                        <li>Напиток безалкогольный газированный «Кока-Кола» Ванилла», 1,5 л;</li>
                                        <li>Напиток безалкогольный газированный «Кока-Кола» Лайм», 0,5 л;</li>
                                        <li>Напиток безалкогольный газированный «Кока-Кола» Лайм», 1 л;</li>
                                        <li>Напиток безалкогольный газированный «Кока-Кола» Лайм», 1,5 л;</li>
                                        <li>Вода питьевая негазированная «Бонаква», 0,5 л;</li>
                                        <li>Вода питьевая негазированная «Бонаква», 1 л;</li>
                                        <li>Вода питьевая негазированная «Бонаква», 1,5 л;</li>
                                        <li>Вода питьевая негазированная «Бонаква», 2 л;</li>
                                        <li>Вода питьевая газированная «Бонаква» Плюс среднегазированная», 0,5 л;</li>
                                        <li>Вода питьевая газированная «Бонаква» Плюс среднегазированная», 1 л;</li>
                                        <li>Вода питьевая газированная «Бонаква» Плюс среднегазированная», 1,5 л;</li>
                                        <li>Вода питьевая газированная «Бонаква» Плюс среднегазированная», 2 л;</li>
                                        <li>Вода питьевая газированная «Бонаква» сильногазированная», 0,5 л;</li>
                                        <li>Вода питьевая газированная «Бонаква» сильногазированная», 1 л;</li>
                                        <li>Вода питьевая газированная «Бонаква» сильногазированная», 1,5 л;</li>
                                        <li>Вода питьевая газированная «Бонаква» сильногазированная», 2 л;</li>
                                        <li>Напиток безалкогольный газированный «Бонаква» со вкусом лимона», 0,5 л;</li>
                                        <li>Напиток безалкогольный газированный «Бонаква» со вкусом лимона», 1 л;</li>
                                        <li>Напиток безалкогольный газированный «Бонаква» со вкусом лимона», 1,5 л;</li>
                                        <li>Напиток безалкогольный газированный «Бонаква» со вкусом лимона», 2 л;</li>
                                        <li>Напиток безалкогольный газированный «Бонаква» со вкусом яблока», 0,5 л;</li>
                                        <li>Напиток безалкогольный газированный «Бонаква» со вкусом яблока», 1 л;</li>
                                        <li>Напиток безалкогольный газированный «Бонаква» со вкусом яблока», 1,5 л;</li>
                                        <li>Напиток безалкогольный газированный «Бонаква» со вкусом яблока», 2 л;</li>
                                        <li>Напиток безалкогольный газированный «Бонаква» со вкусом малины», 0,5 л;</li>
                                        <li>Напиток безалкогольный газированный «Бонаква» со вкусом малины», 1 л;</li>
                                        <li>Напиток безалкогольный газированный «Бонаква» со вкусом малины», 1,5 л;</li>
                                        <li>Напиток безалкогольный газированный «Бонаква» со вкусом малины», 2 л;</li>
                                        <li>Напиток безалкогольный газированный «Кока-Кола», 0,33 л;</li>
                                        <li>Напиток безалкогольный газированный «Кока-Кола» без сахара», 0,33 л;</li>
                                        <li>Напиток безалкогольный газированный «Кока-Кола» Ванилла», 0,33 л;</li>
                                        <li>Напиток безалкогольный газированный «Кока-Кола» Лайм», 0,33 л;</li>
                                        <li>Напиток безалкогольный газированный тонизирующий (энергетический) «БЕРН»
                                            ОРИГИНАЛЬНЫЙ»,
                                            0,25 л;
                                        </li>
                                        <li>Напиток безалкогольный газированный тонизирующий (энергетический) «БЕРН»
                                            ТЕМНАЯ
                                            ЭНЕРГИЯ», 0,25 л;
                                        </li>
                                        <li>Напиток безалкогольный сильногазированный тонизирующий (энергетический)
                                            «БЕРН»
                                            ТРОПИЧЕСКИЙ МИКС», 0,25 л;
                                        </li>
                                        <li>Напиток безалкогольный сильногазированный тонизирующий (энергетический)
                                            «БЕРН» ГОЛД
                                            РАШ», 0,25 л;
                                        </li>
                                        <li>Напиток безалкогольный сильногазированный тонизирующий (энергетический)
                                            «БЕРН»
                                            ОРИГИНАЛЬНЫЙ», 0,449 л;
                                        </li>
                                        <li>Напиток безалкогольный сильногазированный тонизирующий (энергетический)
                                            «БЕРН» ГОЛД
                                            РАШ», 0,449 л;
                                        </li>
                                        <li>Напиток безалкогольный сильногазированный тонизирующий (энергетический)
                                            «БЕРН»
                                            ТЁМНАЯ
                                            ЭНЕРГИЯ», 0,449 л;
                                        </li>
                                        <li>Напиток безалкогольный сильногазированный тонизирующий (энергетический)
                                            «БЕРН»
                                            ТРОПИЧЕСКИЙ МИКС», 0,449 л;
                                        </li>
                                        <li>Напиток безалкогольный сильногазированный тонизирующий (энергетический)
                                            «БЕРН»
                                            СОЧНАЯ
                                            ЭНЕРГИЯ», 0,449 л;
                                        </li>
                                        <li>Напиток безалкогольный сильногазированный тонизирующий (энергетический)
                                            «БËРН» СО
                                            ВКУСОМ
                                            ГУАВЫ», 0,449 л;
                                        </li>
                                        <li>Яблочный сок Rich, 1 л;</li>
                                        <li>Виноградный сок Rich, 1 л;</li>
                                        <li>Грейпфрутовый сок Rich, 1 л;</li>
                                        <li>Апельсиновый сок Rich, 1 л;</li>
                                        <li>Персиковый нектар Rich, 1 л;</li>
                                        <li>Ананасовый сок Rich, 1 л;</li>
                                        <li>Томатный сок с солью Rich, 1 л;</li>
                                        <li>Вишневый нектар Rich, 1 л;</li>
                                        <li>Морс из клюквы и винограда Rich, 1 л;</li>
                                        <li>Нектар из апельсинов и манго Rich, 1 л;</li>
                                        <li>Мультифруктовый сок обогащенный провитамином А Rich, 1 л;</li>
                                        <li>Вишневый нектар Rich (продукт детского питания), 0,3 л;</li>
                                        <li>Яблочный сок Rich (продукт детского питания), 0,3 л;</li>
                                        <li>Апельсиновый сок Rich (продукт детского питания), 0,3 л;</li>
                                        <li>Нектар из апельсинов и манго Rich (продукт детского питания), 0,3 л.</li>
                                        <li>Напиток безалкогольный газированный «Швеппс Индиан Тоник», 0,50 л;</li>
                                        <li>Напиток безалкогольный газированный «Швеппс Индиан Тоник», 1,00 л;</li>
                                        <li>Напиток безалкогольный газированный «Швеппс Биттер Лемон», 0,50 л;</li>
                                        <li>Напиток безалкогольный газированный «Швеппс Биттер Лемон», 1,00 л;</li>
                                        <li>Напиток безалкогольный газированный «Швеппс Биттер Лемон», 1,50 л;</li>
                                        <li>Напиток безалкогольный газированный «Швеппс со вкусом «Пряная Клюква», 0,50
                                            л;
                                        </li>
                                        <li>Напиток безалкогольный газированный «Швеппс со вкусом «Пряная Клюква», 1,00
                                            л;
                                        </li>
                                        <li>Напиток безалкогольный газированный «Швеппс со вкусом «Пряная Клюква», 1,50
                                            л;
                                        </li>
                                        <li>Напиток безалкогольный газированный «Швеппс со вкусом «Розовый Грейпфрут»,
                                            0,50 л;
                                        </li>
                                        <li>Напиток безалкогольный газированный «Швеппс со вкусом «Розовый Грейпфрут»,
                                            1,00 л;
                                        </li>
                                        <li>Напиток безалкогольный газированный «Швеппс со вкусом «Розовый Грейпфрут»,
                                            1,50 л;
                                        </li>
                                        <li>Напиток безалкогольный газированный «Швеппс со вкусом «Мохито», 0,50 л;</li>
                                        <li>Напиток безалкогольный газированный «Швеппс со вкусом «Мохито», 1,00 л;</li>
                                        <li>Напиток безалкогольный газированный «Швеппс со вкусом «Мохито», 1,50 л;</li>
                                        <li>Напиток безалкогольный газированный «Швеппс Спритц Аперитиво», 0,50 л;</li>
                                        <li>Напиток безалкогольный газированный «Швеппс Спритц Аперитиво», 1,00 л;</li>
                                        <li>Напиток безалкогольный газированный «Швеппс Спритц Аперитиво», 1,50 л;</li>
                                        <li>Напиток безалкогольный газированный «Швеппс Биттер Лемон», 0,33 л;</li>
                                        <li>Напиток безалкогольный газированный «Швеппс со вкусом «Пряная клюква», 0,33
                                            л;
                                        </li>
                                        <li>Напиток безалкогольный газированный «Швеппс Индиан Тоник», 0,33 л;</li>
                                        <li>Напиток безалкогольный газированный «Швеппс со вкусом «Розовый Грейпфрут»,
                                            0,33 л;
                                        </li>
                                        <li>Напиток безалкогольный газированный «Швеппс со вкусом «Мохито», 0,33 л;</li>
                                    </ul>
                                </li>
                                <li>Получить Кассовый чек, подтверждающий приобретение Игрового продукта и содержащий
                                    следующие
                                    сведения:
                                    <ul>
                                        <li>сведения о номере документа;</li>
                                        <li>наименование продавца (торгового объекта, где были приобретены Игровые
                                            продукты);
                                        </li>
                                        <li>учетный номер плательщика (УНП);</li>
                                        <li>дату и время продажи Игровых продуктов;</li>
                                        <li>наименование Игровых продуктов;</li>
                                        <li>количество и цену Игровых продуктов;</li>
                                        <li>итоговую сумму покупки.</li>
                                    </ul>
                                </li>
                                <li>Ознакомиться с правилами Рекламной игры, предоставить свое согласие на обработку
                                    персональных данных
                                    для участия в Рекламной игре посредством ознакомления с обязательной информацией об
                                    условиях
                                    обработки персональных данных и проставления отметки в соответствующем поле формы
                                    согласия
                                    на
                                    обработку персональных данных до регистрации на сайте <a
                                        href="https://coca-cola-promo.by/">www.coca-cola-promo.by</a>
                                </li>
                                <li>Зарегистрироваться на Интернет-сайте <a
                                    href="https://coca-cola-promo.by/">www.coca-cola-promo.by</a>, (далее –
                                    Интернет-сайт),
                                    путем
                                    заполнения регистрационной формы на Интернет-сайте, указав Личные данные.
                                </li>
                                <li>Загрузить фотографии чека(-ов) или его(их) отсканированную(-ые) версию(-и) в Личном
                                    кабинете. Один
                                    чек может быть зарегистрирован только один раз. Формат загружаемого чека на сайт:
                                    jpg, png.
                                    Вес
                                    файла: до 10 MB.
                                </li>
                                <li>Сохранить чек(-и) до окончания Рекламной игры.</li>
                            </ol>
                        </>
                    )}
                </div>

                <div
                    className={`${styles.faqCard} ${openIndex === 3 ? styles.active : ''}`}
                    onClick={() => handleToggle(3)}
                >
                    <div className={styles.summary}>
                        <h2>Какие требования к паролю при регистрации на Интернет-сайте www.coca-cola-promo.by для
                            участия в
                            Рекламной игре?</h2>
                        <img
                            src={plus}
                            alt="plus"
                            className={styles.iconPlus}
                            style={{display: openIndex === 3 ? 'none' : 'block'}}
                        />
                        <img
                            src={minus}
                            alt="minus"
                            className={styles.iconMinus}
                            style={{display: openIndex === 3 ? 'block' : 'none'}}
                        />
                    </div>
                    {openIndex === 3 && (
                        <p>Пароль должен содержать не менее 8 символов и содержать как минимум одну заглавную букву,
                            одну цифру и специальный символ.</p>
                    )}
                </div>

                <div
                    className={`${styles.faqCard} ${openIndex === 4 ? styles.active : ''}`}
                    onClick={() => handleToggle(4)}
                >
                    <div className={styles.summary}>
                        <h2>Какие призы разыгрываются в Рекламной игре?</h2>
                        <img
                            src={plus}
                            alt="plus"
                            className={styles.iconPlus}
                            style={{display: openIndex === 4 ? 'none' : 'block'}}
                        />
                        <img
                            src={minus}
                            alt="minus"
                            className={styles.iconMinus}
                            style={{display: openIndex === 4 ? 'block' : 'none'}}
                        />
                    </div>
                    {openIndex === 4 && (
                        <>
                            <p>Каждую неделю разыгрываются призы:</p>
                            <ul>
                                <li>Подарочный сертификат «Корона» номинальной стоимостью 100,00 (сто) белорусских
                                    рублей. Срок
                                    действия: до 6 января 2025.
                                </li>
                                <li>Подарочный набор от Coca-Cola из термосумки и пледа.</li>
                                <li>Портативная колонка Sven PS-380 черного цвета.</li>
                                <li>Электрогриль Redmond SteakMaster RGM-M817D и денежные средства в белорусских рублях
                                    в
                                    размере
                                    возмещения Подоходного налога с физических лиц.
                                </li>
                            </ul>
                            <p>Главный приз</p>
                            <ul>
                                <li>Новый автомобиль NISSAN X-TRAIL цвета черный металлик 2024 года выпуска в
                                    комплектации TOP и
                                    денежные средства в белорусских рублях в размере возмещения Подоходного налога с
                                    физических
                                    лиц.
                                </li>
                            </ul>
                        </>
                    )}
                </div>

                <div
                    className={`${styles.faqCard} ${openIndex === 5 ? styles.active : ''}`}
                    onClick={() => handleToggle(5)}
                >
                    <div className={styles.summary}>
                        <h2>Когда и где проходят розыгрыши призов?</h2>
                        <img
                            src={plus}
                            alt="plus"
                            className={styles.iconPlus}
                            style={{display: openIndex === 5 ? 'none' : 'block'}}
                        />
                        <img
                            src={minus}
                            alt="minus"
                            className={styles.iconMinus}
                            style={{display: openIndex === 5 ? 'block' : 'none'}}
                        />
                    </div>
                    {openIndex === 5 && (
                        <>
                            <p>Розыгрыши еженедельных призов проводятся еженедельно по адресу: Республика Беларусь, г.
                                Минск,
                                пер.Козлова, 25, пом. 22 в 15:00 каждую пятницу с 14 августа 2024 г. по 25 сентября 2024
                                г.
                                (включительно).</p>
                            <p>Последний еженедельный розыгрыш, вместе с розыгрышем Главного приза, пройдет по адресу:
                                Республика
                                Беларусь, г. Минск, пр-т. Победителей, 65, 4 октября 2024 г. в 16:00.</p>
                        </>
                    )}
                </div>

                <div
                    className={`${styles.faqCard} ${openIndex === 6 ? styles.active : ''}`}
                    onClick={() => handleToggle(6)}
                >
                    <div className={styles.summary}>
                        <h2>Каким образом определяются победители?</h2>
                        <img
                            src={plus}
                            alt="plus"
                            className={styles.iconPlus}
                            style={{display: openIndex === 6 ? 'none' : 'block'}}
                        />
                        <img
                            src={minus}
                            alt="minus"
                            className={styles.iconMinus}
                            style={{display: openIndex === 6 ? 'block' : 'none'}}
                        />
                    </div>
                    {openIndex === 6 && (
                        <>
                            <p>В ходе каждого розыгрыша определяется Игровой код-победитель для каждого разыгрываемого
                                приза.</p>
                            <p>Победивший Игровой код определяется с помощью лототрона: члены комиссии поочередно
                                определяют
                                слева
                                направо цифры Игрового кода-победителя, доставая из лототрона шары с соответствующими
                                номерами.</p>
                            <p>Подробнее о процедуре определения Победителей читайте в п.11 Правил Рекламной игры.</p>
                        </>
                    )}
                </div>

                <div
                    className={`${styles.faqCard} ${openIndex === 7 ? styles.active : ''}`}
                    onClick={() => handleToggle(7)}
                >
                    <div className={styles.summary}>
                        <h2>Как я узнаю о выигрыше?</h2>
                        <img
                            src={plus}
                            alt="plus"
                            className={styles.iconPlus}
                            style={{display: openIndex === 7 ? 'none' : 'block'}}
                        />
                        <img
                            src={minus}
                            alt="minus"
                            className={styles.iconMinus}
                            style={{display: openIndex === 7 ? 'block' : 'none'}}
                        />
                    </div>
                    {openIndex === 7 && (
                        <>
                            <p>Победители Рекламной игры оповещаются о выигрыше посредством звонка на абонентский номер
                                мобильного
                                телефона белорусского оператора сотовой связи в сроки, указанные в Таблице 3 п. 12.2
                                Правил.</p>
                            <p>Организатор также оставляет за собой право дополнительно уведомлять победителей любым
                                удобным ему
                                способом: посредством SМS-рассылки на указанные абонентские номера мобильных телефонов
                                белорусских
                                операторов сотовой связи, указанные при регистрации на Интернет-сайте, почтовым
                                отправлением, а
                                также
                                электронным письмом по адресу электронной почты Участника, которые были указаны при
                                регистрации
                                на
                                Интернет-сайте.</p>
                            <p>Кроме того, информацию о победителях Вы сможете найти на сайте www.coca-cola-promo.by.
                                Также
                                информация о
                                победителях будет опубликована в газете «Аргументы и Факты» в срок не позднее 8 декабря
                                2024
                                года
                                включительно.</p>
                        </>
                    )}
                </div>

                <div
                    className={`${styles.faqCard} ${openIndex === 8 ? styles.active : ''}`}
                    onClick={() => handleToggle(8)}
                >
                    <div className={styles.summary}>
                        <h2>Сколько призов можно выиграть?</h2>
                        <img
                            src={plus}
                            alt="plus"
                            className={styles.iconPlus}
                            style={{display: openIndex === 8 ? 'none' : 'block'}}
                        />
                        <img
                            src={minus}
                            alt="minus"
                            className={styles.iconMinus}
                            style={{display: openIndex === 8 ? 'block' : 'none'}}
                        />
                    </div>
                    {openIndex === 8 && (
                        <>
                            <p>Каждый Участник может принимать участие в Рекламной игре неограниченное количество раз
                                при
                                условии
                                покупки Игрового продукта, выполнения условий, необходимых для участия в Рекламной
                                игре.</p>
                            <p>При этом каждый Участник может выиграть не более одного одноименного приза в рамках
                                одного
                                розыгрыша.
                                Главный приз разыгрывается между всеми участниками Рекламной игры без каких-либо
                                ограничений.</p>
                        </>
                    )}
                </div>

                <div
                    className={`${styles.faqCard} ${openIndex === 9 ? styles.active : ''}`}
                    onClick={() => handleToggle(9)}
                >
                    <div className={styles.summary}>
                        <h2>Каким образом будут выдаваться призы?</h2>
                        <img
                            src={plus}
                            alt="plus"
                            className={styles.iconPlus}
                            style={{display: openIndex === 9 ? 'none' : 'block'}}
                        />
                        <img
                            src={minus}
                            alt="minus"
                            className={styles.iconMinus}
                            style={{display: openIndex === 9 ? 'block' : 'none'}}
                        />
                    </div>
                    {openIndex === 9 && (
                        <>
                            <p>Призы: Подарочная карточка (сертификат) «КОРОНА» номинальной стоимостью 100,00 (сто)
                                белорусских
                                рублей;
                                Подарочный набор от Coca-Cola из термосумки и пледа выдаются Победителям по адресу:
                                Республика
                                Беларусь,
                                г. Минск, пер. Козлова, 25, пом. 22 в рабочие дни с 10.00 до 17.00, в субботу с 13.00 до
                                15.00 в
                                день
                                явки Победителя за получением Приза либо направляются по почте в течение месяца со дня
                                проведения
                                розыгрыша. Для получения приза необходимо предварительно связаться с представителем
                                Организатора
                                по
                                телефону +375 44 778 11 00 (звонок тарифицируется согласно тарифам оператора, услугами
                                которого
                                пользуется Участник), либо не позднее чем за 5 (пять) дней до окончания периода выдачи
                                приза
                                направить
                                соответствующее заявление на электронную почту Организатора
                                (info@coca-cola-promo.by)</p>
                            <p>Призы: Портативная колонка Sven PS-380 черного цвета; Электрогриль Redmond SteakMaster
                                RGM-M817D
                                и
                                денежные средства в белорусских рублях в размере возмещения Подоходного налога с
                                физических лиц;
                                Новый
                                автомобиль NISSAN X-TRAIL цвета черный металлик 2024 года выпуска в комплектации TOP и
                                денежные
                                средства
                                в белорусских рублях в размере возмещения Подоходного налога с физических лиц выдаются
                                Победителям по
                                адресу: Республика Беларусь, г. Минск, пер. Козлова, 25, пом. 22 в рабочие дни с 10.00
                                до 17.00,
                                в
                                субботу с 13.00 до 15.00 в день явки Победителя за получением Приза в течение месяца со
                                дня
                                проведения
                                розыгрыша. Для получения приза необходимо предварительно связаться с представителем
                                Организатора
                                по
                                телефону +375 44 778 11 00 (звонок тарифицируется согласно тарифам оператора, услугами
                                которого
                                пользуется Участник).</p>
                            <p>Призы выдаются Победителям после предоставления Победителями Организатору документа,
                                удостоверяющего
                                личность (паспорта, ID-карты или вида на жительство) или передаются почтовым
                                отправлением по
                                предоставлению соответствующих данных. Подробнее о возможности получения призов по почте
                                читайте
                                в
                                п.12.3 Правил Рекламной игры.</p>
                        </>
                    )}
                </div>
            </div>
        </section>
    );
};