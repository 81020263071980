import React, {useEffect, useState} from 'react';
import {Link, Route, Routes} from "react-router-dom";
import {Projects} from "./Projects";
import styles from './css/Admin.module.css';
import {Managers} from "./Managers";
import {ManagerDetails} from "./ManagerDetails";
import {NewProject} from "./NewProject";
import {api} from "../../index";
import {Raffles} from "./Raffles";
import {NewRaffle} from "./NewRaffle";
import {Raffle} from "./Raffle";
import {Project} from "./Project";

export const Admin = () => {
    const [projects, setProjects] = useState([]);

    const fetchProjects = () => {
        fetch(`${api}/projects/`, {
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((data) => setProjects(data))
            .catch((err) => console.error('Error adding project:', err));
    };

    useEffect(() => {
        fetchProjects();
    }, []);

    return (
        <div className={styles.admin}>
            <aside className={styles.menu}>
                <h2>Проекты</h2>
                <div className={styles.projects}>
                    {(projects ?? []).map((project) => (
                        <Link key={project.uuid} className={styles.button} to={`/admin/project/${project.uuid}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                                 fill="#000">
                                <path
                                    d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h240l80 80h320q33 0 56.5 23.5T880-640v400q0 33-23.5 56.5T800-160H160Zm0-80h640v-400H447l-80-80H160v480Zm0 0v-480 480Z"/>
                            </svg>
                            <div>{project.name}</div>
                        </Link>
                    ))}
                    <Link className={styles.button} to={`/admin/project/new`}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                             fill="#000">
                            <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"/>
                        </svg>
                        <div>Добавить проект</div>
                    </Link>
                </div>
            </aside>
            <section className={styles.content}>
                <Routes>
                    <Route path="/" element={<Projects projects={projects}/>}/>
                    <Route path="/project/new" element={<NewProject callback={fetchProjects}/>}/>
                    <Route path="/project/:projectUuid" element={<Project/>}/>
                    <Route path="/project/:projectUuid/raffles" element={<Raffles/>}/>
                    <Route path="/project/:projectUuid/raffles/new" element={<NewRaffle/>}/>
                    <Route path="/project/:projectUuid/raffles/:raffleId/receipts" element={<Raffle/>}/>
                    {/*<Route path="/project/:uuid" element={<ProjectDetails/>}/>*/}

                    <Route path="/project/:uuid/users" element={<h1>Users</h1>}/>
                    <Route path="/project/:uuid/users/:userId" element={<h1>User</h1>}/>

                    <Route path="/managers" element={<Managers/>}/>
                    <Route path="/managers/:id" element={<ManagerDetails/>}/>
                </Routes>
            </section>
            {/*<HeaderAdmin/>*/}
            {/*<NavigationAdmin/>*/}

            {/*<FooterAdmin/>*/}
        </div>
    );
};