import React from 'react';
import styles from './css/Banner.module.css';
import image3 from '../../img/image3.svg';
import image27 from "../../img/image27.svg";

export const Banner = () => {
    return (
        <div className={styles.banner} style={{ backgroundImage: `url(${image27})` }}>
            <img src={image3} alt="Banner"/>
        </div>
    );
};
