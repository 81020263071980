import React from 'react';
import styles from './css/Projects.module.css';
import {Link} from "react-router-dom";

export const Projects = ({projects}) => {
    return (
        <div className={styles.projects}>
            <h1 className={styles.h1}>Проекты</h1>
            <div className={styles.table}>
                {(projects ?? []).map((project) => (
                    <div key={project.uuid} className={styles.row}>
                        <div className={styles.name}>
                            <Link to={`/admin/project/${project.uuid}/raffles`} className={styles.link}>
                                <span>{project.name}</span>
                            </Link>
                        </div>
                        <div className={styles.uuid}>{project.uuid}</div>
                        <div className={styles.started}>{new Date(project.started).toLocaleDateString()}</div>
                        <div className={styles.finished}>{new Date(project.finished).toLocaleDateString()}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};